import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueCookies from 'vue-cookies';
import * as Sentry from "@sentry/vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Klein Vossen Park';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue')
        return pages[`./Pages/${name}.vue`]()
    },
    progress: {
        color: '#1466d9',
    },
    setup({ el, App, props, plugin }) {
        let vue = createApp({
            render: () => h(App, props), components: {
                // Add your components here
                vSelect
            }
        })

        Sentry.init({
            app: vue,
            dsn: "https://26a26b7eb90ac6120e4b29dce0ba394b@o4506316512493568.ingest.sentry.io/4506316595068928",
            environment: "production",
            logErrors: true,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.browserProfilingIntegration(),
                Sentry.metrics.metricsAggregatorIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    maskAllInputs: false,
                    blockAllMedia: false
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        vue.use(plugin)
        vue.use(ZiggyVue, Ziggy)
        vue.use(VueCookies)

        return vue.mount(el);
    },
}).then(r => {
    console.log(r)
});
